/* eslint-disable */
/* stylelint-disable */
// @ts-nocheck

import {
    CategoryDetailsComponent as SourceCategoryDetailsComponent,
} from "SourceComponent/CategoryDetails/CategoryDetails.component";
import ChevronIcon from 'Component/ChevronIcon';
import Html from 'Component/Html';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { 
    SHOW_SUB_CATEGORY_LIST
} from "Route/CategoryPage/CategoryPage.config";

import './CategoryDetails.override.style.scss';

/**
 * Category details
 * @class CategoryDetails
 * @namespace Component/CategoryDetails/Component
 */
export class CategoryDetailsComponent extends SourceCategoryDetailsComponent {

    state = {
        isDescriptionExpanded: false
    }

    
    toggleDescription = () => {
        this.setState((prevState) => ({
            isDescriptionExpanded: !prevState.isDescriptionExpanded
        }));
    }
    
    renderCategoryDescriptionPlaceholder() {
        return (
            <p block="CategoryDescription" elem="Placeholder">
                <TextPlaceholder length={ TextPlaceHolderLength.LONG } />
            </p>
        );
    }

    renderCategoryDescription() {
        const {
            category: { description, id },
            isCurrentCategoryLoaded,
        } = this.props;

        const { isDescriptionExpanded } = this.state;
        const { isPrefetchValueUsed, actionName: { name: preloadDescription } } = window;

        if (isPrefetchValueUsed && !isCurrentCategoryLoaded) {
            if (preloadDescription) {
                return <Html content={ preloadDescription } />;
            }
            return null;
        }

        if ((!id || !isCurrentCategoryLoaded) && !preloadDescription) {
            return this.renderCategoryDescriptionPlaceholder();
        }

        if (!description) {
            return null;
        }

        return (
            <div block="CategoryDetails" elem="DescriptionWrapper">
                <div className={`category-description ${isDescriptionExpanded ? 'expanded' : 'collapsed'}`}>
                    <Html content={description} />
                </div>
                <button
                    block="CategoryDetails" elem="ToggleButton"
                    onClick={this.toggleDescription}
                >
                    {isDescriptionExpanded ? __('Read Less') : __('Read More') } <ChevronIcon direction={ Directions.RIGHT } />
                </button>
            </div>
        );
    }

    isRenderImage() {
        const {
            category
        } = this.props

        // Show sub category list for specific category
        let allowShowSubCategories = true;
        SHOW_SUB_CATEGORY_LIST.forEach((subCategory) => {
        if (
            category &&
            category.url.indexOf(subCategory) === -1
        ) {
            allowShowSubCategories = false;
        }
        });

        return allowShowSubCategories;
    }

    renderCategoryImage() {
        const {
            category: { image, id },
            isCurrentCategoryLoaded
        } = this.props;

        if (!id || !isCurrentCategoryLoaded) {
            return this.renderCategoryImagePlaceholder();
        }

        if (!image) {
            return null;
        }

        if(this.isRenderImage()){
            return null;
        }
        
        return (
            <Image
              mix={ { block: 'CategoryDetails', elem: 'Picture' } }
              src={ image || '' }
              ratio={ ImageRatio.IMG_CUSTOM }
              isShowTextImageNotFound={ false }
            />
        );
    }

}

export default CategoryDetailsComponent;
