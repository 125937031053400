Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/faqs/src/plugin/CmsPageComponent.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatch from 'Route/NoMatch';
import Footer from 'Component/Footer';
import history from 'Util/History';

import './CmsPage.override.style';
import { CmsPageComponent as SourceCmsPageComponent } from 'SourceRoute/CmsPage/CmsPage.component';

/** @namespace Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPageComponent {
    static defaultProps = {
        isBreadcrumbsActive: true,
    };

    componentDidMount() {
        this.manageExtrenalClass();
    }

    componentDidUpdate(prevProps) {
        this.manageExtrenalClass();
    }

    renderHeading() {
        const { cmsPage: { content_heading }, isLoading } = this.props;

        if (!content_heading || isLoading) {
            return null;
        }

        return (
            <h1 block="CmsPage" elem="Heading">
                <TextPlaceholder content={ content_heading } />
            </h1>
        );
    }

    renderContent() {
        const {
            isLoading,
            cmsPage: { content },
        } = this.props;
        
        if (isLoading) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (!content) {
            return null;
        }

        return (
        <>
            <Html content={ content } />
            <Footer isVisibleOnMobile />
        </>
        );
    }

    manageExtrenalClass() {
        const self = this
        const { location: { pathname } } = history;
        const extrenalPageClass = setInterval(async () => {
            const links = document.querySelectorAll(".Menu-Link a")
            const cmsPageMain = document.querySelector(".CmsPage")
            if(links.length > 0 && cmsPageMain){
                clearInterval(extrenalPageClass)
                links.forEach((link) => {
                    const hrefCode = link.getAttribute('href')
                    if(hrefCode === pathname){
                        cmsPageMain.classList.add("ExternalCmsPage")
                        self.manageReadMoreBtn()
                    }
                })
            }
        }, 10);

        setTimeout(() => {
            clearInterval(extrenalPageClass)
        }, 10000)
    }

    manageReadMoreBtn () {

        if(document.getElementById("readmore-btn")){
            document.getElementById("readmore-btn")?.addEventListener("click", function() {
                document.querySelector(".hm-descr-body")?.classList?.add("collapse");
            })
        }
        if(document.getElementById("readless-btn")){
            document.getElementById("readless-btn")?.addEventListener("click", function() {
                document.querySelector(".hm-descr-body")?.classList?.remove("collapse");
            })
        }

    }

    render() {
        const {
            cmsPage,
            isBreadcrumbsActive,
            isLoading,
        } = this.props;
        const { page_width, title, content } = cmsPage;

        if (!isLoading && !title && !content) {
            return <NoMatch />;
        }

        return (
            <main
              block="CmsPage"
              mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
            >
                <div block="CmsPage" elem="Wrapper" mods={ { page_width } }>
                    { this.renderHeading() }
                    <div block="CmsPage" elem="Content">
                        { this.renderContent() }
                    </div>
                </div>
            </main>
        );
    }
}

export default CmsPageComponent;
