/* eslint-disable */
/* stylelint-disable */
// @ts-nocheck

import {
    ProductLinksComponent as SourceProductLinksComponent,
} from "SourceComponent/ProductLinks/ProductLinks.component";
import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from 'Component/ProductCard';
// import ProductFbt from 'Component/ProductFbt';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import './ProductLinks.override.style';

/** @namespace Component/ProductLinks/Component */
export class ProductLinksComponent extends SourceProductLinksComponent {

    renderProductCard(product, i) {

        const { id = i } = product;

        return (
            <SwiperSlide>
                <ProductCard
                block="ProductLinks"
                elem="Card"
                product={ product }
                key={ id }
                />
            </SwiperSlide>
        );
    }

    renderHeading() {

        const { title} = this.props;

        return (
            <div block="page-section" elem="head">
                <h2  block="page-section" elem="title">{ title }</h2>
            </div>
        );
    }  

    render() {
        const { areDetailsLoaded, linkType, product} = this.props;

        if (!areDetailsLoaded) {
            return null;
        }
        
        const nextSliderClass = linkType ? 'products-next-'+ linkType : '';
        const prevSliderClass = linkType ? 'products-prev-'+ linkType : '';
        
        return (
            <div block="ProductLinks" elem= 'Section'>
               {/* <ProductFbt data={product}></ProductFbt> */}
                <ContentWrapper
                    mix={ { block: 'ProductLinks' } }
                    wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
                    label={ __('Linked products') }
                >
                    { this.renderHeading() }
                    <div block="ProductListPage" elem="SliderWrapper">
                        <Swiper
                            spaceBetween={15}
                            slidesPerView={2}
                            navigation={{
                                nextEl: '.'+nextSliderClass,
                                prevEl: '.'+prevSliderClass,
                            }} 
                            modules={[Navigation]}
                            breakpoints={{
                                640: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                                },
                                768: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                                },
                                1024: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                                },
                            }}
                        >
                            { this.renderItems() }
                        </Swiper>
                        <div className={"swiper-button-prev " + prevSliderClass} />
                        <div className={"swiper-button-next " + nextSliderClass} />
                    </div>
                </ContentWrapper>
            </div>
        );
    }
}

export default ProductLinksComponent;
