Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/faqs/src/plugin/CategoryPageComponent.plugin.js')]);
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import { CategoryPageComponent as SourceCategoryPage } from "SourceRoute/CategoryPage/CategoryPage.component";
import ContentWrapper from "Component/ContentWrapper";
import ChevronIcon from "Component/ChevronIcon";
import { Directions } from "Component/ChevronIcon/ChevronIcon.config";
import Footer from "Component/Footer";
import SubCategory from "Component/SubCategory";
import ProductCard from "Component/ProductCard";

import "./CategoryPage.override.style";

/** @namespace Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPage {
  state = {
    activeLayoutType: undefined,
    isDescriptionExpanded: false,
  };

  toggleDescription = () => {
    this.setState({ isDescriptionExpanded: !this.state.isDescriptionExpanded });
  };

  renderCmsBlock() {
    const { isDescriptionExpanded } = this.state;
    const {
      category: { cms_block },
      isCurrentCategoryLoaded,
    } = this.props;

    const descriptionExpandedActive = isDescriptionExpanded ? "active" : "";

    if (!cms_block || !this.displayCmsBlock() || !isCurrentCategoryLoaded) {
      return null;
    }

    const { content, disabled } = cms_block;

    if (disabled) {
      return null;
    }

    return (
      <div
        block="CategoryPage"
        elem="CMSWrapper"
        mix={{ block: descriptionExpandedActive }}
      >
        <div
          block="CategoryPage"
          elem="CMS"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div block="CategoryPage" elem="CMSReadmore">
          <div
            block="CategoryPage"
            elem="CMSReadmorebtn"
            onClick={this.toggleDescription}
          >
            {descriptionExpandedActive ? __("Read Less") : __("Read More")}{" "}
            <ChevronIcon direction={Directions.RIGHT} />
          </div>
        </div>
      </div>
    );
  }

  displayProducts() {
    const { isSubCategoryPage } = this.props;
    if (isSubCategoryPage) {
      return false;
    }

    return super.displayProducts();
  }

  renderSubCategoryContent() {
    const { isCurrentCategoryLoaded } = this.props;

    if (!isCurrentCategoryLoaded) {
      return null;
    }

    const {
      category,
      category: { children: childrens },
    } = this.props;

    return (
      <SubCategory
        isCurrentCategoryLoaded={isCurrentCategoryLoaded}
        category={category}
        childrens={childrens}
      />
    );
  }

  renderSimilarProductsCard(similar_products) {
    return (
      <>
        {similar_products.map((product, i) => (
          <ProductCard key={product.id || i} product={product} />
        ))}
      </>
    );
  }

  renderSimilarProducts() {
    const {
      similarProducts,
      category: { similar_product_title },
    } = this.props;

    if (similar_product_title) {
      return (
        <div block="CategoryPage" elem="SimilarProducts">
          <h2 block="CategoryPage" elem="SimilarProductsTitle">
            {similar_product_title}
          </h2>
          <div block="CategoryPage" elem="SimilarProductList">
            {this.renderSimilarProductsCard(similarProducts)}
          </div>
        </div>
      );
    }

    return null;
  }

  renderContent() {
    const { isSubCategoryPage } = this.props;

    if (isSubCategoryPage) {
      return this.renderSubCategoryContent();
    }

    return (
      <>
        {this.renderFilterOverlay()}
        {this.renderCategoryDetails()}
        {/* { this.renderCmsBlock() } */}
        {this.renderMiscellaneous()}
        {this.renderCategoryProductList()}
        {this.renderSimilarProducts()}
      </>
    );
  }

  render() {
    const hideProducts = !this.displayProducts();
    const { totalItems, mobileBackdrop } = this.props;

    return (
      <main
        block="CategoryPage"
        mods={{ noResults: totalItems === 0 }}
        ref={this.categoryPageRef}
      >
        <ContentWrapper
          wrapperMix={{
            block: "CategoryPage",
            elem: "Wrapper",
            mods: { hideProducts },
          }}
          label="Category page"
        >
          <div
            block="CategoryPage"
            elem="MobileBackdrop"
            ref={mobileBackdrop}
          />
          <div block="CategoryPage" elem="Loader" mods={{}} />
          {this.renderContent()}
          {this.renderCmsBlock()}
        </ContentWrapper>
        <Footer isVisibleOnMobile />
      </main>
    );
  }
}

export default CategoryPageComponent;
