// @ts-nocheck
/* eslint-disable */

import SubCategoryQuery from 'Query/SubCategory.query';
import ProductListQuery from 'Query/ProductList.query';
import { fetchQuery } from 'Util/Request/Query';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { getErrorMessage } from 'Util/Request/Error';
import {
    updateBrandsAction,
    updateProductsAction,
    isLoadingAction
} from './SubCategory.action';

/** @namespace Store/SubCategory/Dispatchee */
export class SubCategoryDispatcher {

    async fetchProducts(
        dispatch,
        filters
    ) {
        try {

            dispatch(isLoadingAction(true))

            const { products: { items } } = await fetchQuery([ProductListQuery.getQuery(filters)]);

            dispatch(updateProductsAction(items))
            
        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
        }finally {
            dispatch(isLoadingAction(false))
        }
    }

    async fetchBrands(
        dispatch,
        filters
    ) {
        try {

            dispatch(isLoadingAction(true))

            const { categories: {
                items
            }} = await fetchQuery(
                SubCategoryQuery.getCategories(filters),
            );

            dispatch(updateBrandsAction(items))
            
        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
        }finally {
            dispatch(isLoadingAction(false))
        }
    }
}

export default new SubCategoryDispatcher();
