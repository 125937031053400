/* eslint-disable */
// @ts-nocheck

import { PureComponent } from 'react';
import { RWebShare } from "react-web-share";

import './Share.style';

/**
 * Button for share data
 * @class Share
 * @namespace Component/Share/Component
 */
export class ShareComponent extends PureComponent {

    render() {

        const { text, url, title } = this.props

        return (
            <RWebShare
                data={{
                    text: text,
                    url: url,
                    title: title || __('Share'),
                }}
                onClick={() => console.log("shared successfully!")}
            >
                <button
                    onClick={ () => { } }
                    block="Share"
                    elem="Button"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" id="share" stroke="#000">
                        <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                            <path d="M1 11v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8M13 5 9 1 5 5M9 1v13"></path>
                        </g>
                    </svg>
                </button>
            </RWebShare>
        );
    }
}

export default ShareComponent;
