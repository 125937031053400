/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import { connect } from "react-redux";
import {
  CategoryPageContainer as SourceCategoryPageContainer,
  mapDispatchToProps as SourceMapDispatchToProps,
  mapStateToProps as SourceMapStateToProps,
} from "SourceRoute/CategoryPage/CategoryPage.container";

import CategoryPage from "./CategoryPage.component";
import { SHOW_SUB_CATEGORY_LIST } from "./CategoryPage.config";

import SubCategoryDispatcher from "Store/SubCategory/SubCategory.dispatcher";

/** @namespace Route/CategoryPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
  ...SourceMapStateToProps(state),
  similarProducts: state.SubCategoryReducer.products,
});

/** @namespace Route/CategoryPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
  ...SourceMapDispatchToProps(dispatch),
  fetchProducts: (filters) =>
    SubCategoryDispatcher.fetchProducts(dispatch, filters),
  fetchBrands: (filters) =>
    SubCategoryDispatcher.fetchBrands(dispatch, filters),
});

/** @namespace Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
  componentDidMount() {
    super.componentDidMount();
    this.requestCategory();
  }

  containerProps() {
    const { similarProducts } = this.props;
    return {
      ...super.containerProps(),
      isSubCategoryPage: this.isSubCategoryPage(),
      similarProducts: similarProducts,
    };
  }

  isSubCategoryPage() {
    const { category } = this.props;

    // Default category page Show sub category list
    if (Object.keys(category).length === 0) {
      return true;
    }

    // Show sub category list for specific category
    let allowShowSubCategories = true;
    SHOW_SUB_CATEGORY_LIST.forEach((subCategory) => {
      if (
        category.url.indexOf(subCategory) === -1 ||
        category.children.length === 0
      ) {
        allowShowSubCategories = false;
      }
    });

    return allowShowSubCategories;
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    const { category } = this.props;
    if (category.id !== prevProps.category.id) {
      this.getSimilarProduct();
    }
  }

  getSimilarProduct() {
    const {
      fetchProducts,
      category: { similar_product },
    } = this.props;

    if (similar_product) {
      let productsSku = similar_product.split(",").map((product) => {
        return product.trim();
      });

      const filters = {
        args: {
          filter: {
            productsSkuArray: productsSku,
          },
          currentPage: 1,
          pageSize: 20,
        },
      };

      fetchProducts(filters);
    }
  }

  render() {

    return (
      <CategoryPage {...this.containerProps()} {...this.containerFunctions} />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryPageContainer);
