/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */ 

import { connect } from 'react-redux';
import {
    RouterContainer as SourceRouterContainer,
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps
} from 'SourceComponent/Router/Router.container';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import { DEMO_NOTICE_HEIGHT } from 'Component/Router/Router.config';

/** @namespace Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
});

/** @namespace Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({

    ...SourceMapDispatchToProps(dispatch),
    customInit: () => waitForPriorityLoad().then(
        () => {
            import (
                'Store/CityPricing/CityPricing.dispatcher'
            ).then(({ default: dispatcher }) => dispatcher.getSavedCityCookies(dispatch));
            import (
                'Store/CityPricing/CityPricing.dispatcher'
            ).then(({ default: dispatcher }) => dispatcher.fetchRegions(dispatch));
        }
    )
});

/** @namespace Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {

    setDemoNoticeHeight() {
        const {
            demo_notice,
        } = this.props;
        
       let DemoNotice = demo_notice?.toString() == '0' ? '0' : DEMO_NOTICE_HEIGHT;

        if (demo_notice) {
            const root = document.querySelector(':root');
            root?.style.setProperty('--demo-notice-height', `${DemoNotice}px`);
        }
    }

    initializeApplication() {
        const { customInit } = this.props;
        super.initializeApplication();
        customInit();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
