/* eslint-disable */
// @ts-nocheck
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// eslint-disable-next-line react/no-deprecated
import { createRoot } from 'react-dom/client';

import App from 'SourceComponent/App';
import { waitForPriorityLoad } from 'SourceUtil/Request/LowPriorityLoad';
import { removeAllCacheStorage } from "Util/Cache/Cache";

import 'SourceStyle/main';

// let's register service-worker
waitForPriorityLoad().then(
    /** @namespace Render/waitForPriorityLoad/then */
    () => {

        removeAllCacheStorage();

        if ('serviceWorker' in navigator) {
            const swUrl = '/service-worker.js';
            navigator.serviceWorker.register(swUrl, { scope: '/' });
        }

        if (window.metaHtml) {
            const doc = new DOMParser().parseFromString(window.metaHtml, 'text/html');
            Object.values(doc?.head?.children || {}).forEach((node) => document.head.appendChild(node));
        }
    },
);

// Code bellow enables the hot reloading of plugins
// Why? I have no idea. Further debugging needed.
// TODO: understand why this helps HMR
if (module.hot) {
    module.hot.accept();
}

function HotApp() {
    return <App />;
}

let renderRootInterval = setInterval(() => {
    const container = document.getElementById('root');
    if(container){
        clearInterval(renderRootInterval);
        const root = createRoot(container); 
        root.render(<HotApp />);
    }
}, 100)

