// @ts-nocheck
/* eslint-disable */

import {
    ConfigQuery as SourceConfigQuery,
} from "SourceQuery/Config.query";

import { Field } from '@tilework/opus';

export class ConfigQuery extends SourceConfigQuery {

    _getStoreConfigFields() {
        return [
            ...super._getStoreConfigFields(),
            new Field('locale'),
            new Field('elightwalk_citypricing_enable'),
            new Field('elightwalk_citypricing_window_title')
        ];
    }
}

export default new ConfigQuery();