/* eslint-disable */
// @ts-nocheck

import { connect } from "react-redux";
import { PureComponent } from "react";
import SubCategory from "./SubCategory.component";
import SubCategoryDispatcher from "Store/SubCategory/SubCategory.dispatcher";

/** @namespace Component/SubCategory/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
  brands: state.SubCategoryReducer.brands,
  products: state.SubCategoryReducer.products,
  isLoading: state.SubCategoryReducer.isLoading,
});

/** @namespace Component/SubCategory/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
  fetchProducts: (filters) =>
    SubCategoryDispatcher.fetchProducts(dispatch, filters),
  fetchBrands: (filters) =>
    SubCategoryDispatcher.fetchBrands(dispatch, filters),
});

/** @namespace Component/SubCategory/Container */
export class SubCategoryContainer extends PureComponent {
  __construct(props) {
    super.__construct(props);

    this.getSimilarBrand();
    // this.getSimilarProduct();
  }

  getSimilarBrand() {
    const {
      fetchBrands,
      category: { similar_brand },
    } = this.props;

    if (similar_brand) {
      let brands = similar_brand.split(",").map((brand) => {
        return brand.trim();
      });

      const filters = {
        ids: {
          in: brands,
        },
      };

      fetchBrands(filters);
    }
  }

  // getSimilarProduct() {
  //   const {
  //     fetchProducts,
  //     category: { similar_product },
  //   } = this.props;

  //   if (similar_product) {
  //     let productsSku = similar_product.split(",").map((product) => {
  //       return product.trim();
  //     });

  //     const filters = {
  //       args: {
  //         filter: {
  //           productsSkuArray: productsSku,
  //         },
  //         currentPage: 1,
  //         pageSize: 20,
  //       },
  //     };

  //     fetchProducts(filters);
  //   }
  // }

  containerFunctions = {};

  containerProps() {
    return {
      ...this.props,
    };
  }

  render() {
    return (
      <SubCategory {...this.containerProps()} {...this.containerFunctions} />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubCategoryContainer);
