/* eslint-disable */
// @ts-nocheck

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import Share from './Share.component';

/** @namespace Component/Share/Container/mapStateToProps */
export const mapStateToProps = (state) => ({});

/** @namespace Component/Share/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({});

/* @namespace Component/Share/Container */
export class ShareContainer extends PureComponent {
    static defaultProps = {
        text: "",
        url: window.location.href,
        title: '',
    };

    containerFunctions = {};

    containerProps() {
        const { text, url, title } = this.props

        return {
            text,
            url,
            title
        };
    }

    render() {
        return (
            <Share
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareContainer);
