/* eslint-disable */
/* stylelint-disable */
// @ts-nocheck

import { PureComponent } from 'react';
import ProductCard from 'Component/ProductCard';
import ContentWrapper from 'Component/ContentWrapper';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import { formatPrice } from 'Util/Price';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import './ProductFbt.style';

export const AddToCart = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/AddToCart'),
);
/**
 * ProductFbt
 * @class ProductFbt
 /* @namespace Component/ProductFbt/Component */

export class ProductFbtComponent extends PureComponent {

    renderProductCard(product, i) {

        return (
            <ProductCard
                product={ product }
                key={ i }
            />
        );
    }

    renderFbtItems(fbtProducts){

        const { checkedOrUnCheckedProduct } = this.props;

        const totalProducts = fbtProducts.length - 1;

        return fbtProducts.map((fbtProduct, i) => {
            const { product, isSelected } = fbtProduct
            return (
                <div key={i} block="ProductPage" elem="FbtItem">
                    <div block="ProductPage" elem="FbtItembox">
                        <div block="ProductPage" elem="FbtItemCheckbox">
                            <Field
                                type={ FieldType.CHECKBOX }
                                attr={ {
                                    id: `option-${ product.id }`,
                                    name: `option-${ product.id }`,
                                    checked: isSelected,
                                } }
                                label={ product.sku }
                                isDisabled={ false }
                                events={ {
                                    onChange: (event) => checkedOrUnCheckedProduct(product, event.target.checked)
                                } }
                            />
                        </div>
                        {this.renderProductCard(product, i)}
                    </div>
                    {i < totalProducts && <div block="ProductPage" elem="FbtItemPlus"> { __('+') } </div>}
                </div>
            )
        });
    }

    getTotalPrice(fbtProducts) {
        let total = 0;

        if (!fbtProducts.length) {
            return total;
        }

        fbtProducts.forEach((fbtProduct)=>{
            const { product, isSelected } = fbtProduct

            if(isSelected){
                total += product.price_range?.minimum_price?.default_final_price?.value || 0;
            }
        })

        return total
    }

    getCurrencyCode(fbtProducts) {
        return fbtProducts[0].product.price_range?.minimum_price?.final_price?.currency || 'USD';
    }

    getCheckedProducts() {
        const { checkedProducts } = this.props;
        let items = []
        checkedProducts.forEach((checkedProduct) => {
            if(checkedProduct.isSelected){
                items.push(checkedProduct)
            }
        })
        return {
            items: items,
            total: items.length
        }
    }
 
    render() {
        const { addToCart, isAdding, checkedProducts, addToCartAvailable } = this.props;

        if (!checkedProducts.length) {
            return null;
        }

        const selectedItems = this.getCheckedProducts();
        
        return (
            <div block="ProductPage" elem="BoughtWrapper">
                <ContentWrapper
                    wrapperMix={ { block: 'ProductPage', elem: 'BoughtContainer' } }
                >
                    <div block="ProductPage" elem="BoughtSection">
                        <div block="page-section" elem="head">
                            <h2 block="page-section" elem="title">{ __('Frequently Bought Together') }</h2>
                        </div>
                        <div block="page-section" elem="body">
                            <div block="ProductPage" elem="FbtItems">
                                {this.renderFbtItems(checkedProducts)}
                            </div>
                            <div block="page-section" elem="actions">
                                <button
                                    onClick={addToCart}
                                    block="Button FbtAddToCart"
                                    disabled={ isAdding || addToCartAvailable() }
                                >
                                    {isAdding && <span>{__('Adding...')}</span>}
                                    {!isAdding &&
                                        <>
                                            <span>{ __('Buy All') }&nbsp;{selectedItems.total}:&nbsp;</span>
                                            <span>
                                                { formatPrice(this.getTotalPrice(checkedProducts), this.getCurrencyCode(checkedProducts)) }
                                            </span>
                                        </>
                                    }
                                </button> 
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        );
    }
}
export default ProductFbtComponent;