/* eslint-disable */
// @ts-nocheck

import { ImageContainer as SourceImageContainer } from 'SourceComponent/Image/Image.container';
import Image from './Image.component';

/** @namespace Component/Image/Container */
export class ImageContainer extends SourceImageContainer
{
    static defaultProps = {
        ...SourceImageContainer.defaultProps,
        isShowPlaceholder: false,
        isShowTextImageNotFound: true,
    };

    containerProps() {
        const superProps = super.containerProps();
        const { isShowPlaceholder, isShowTextImageNotFound } = this.props;
       
        return {
            ...superProps,
            isShowPlaceholder: isShowPlaceholder,
            isShowTextImageNotFound: isShowTextImageNotFound
        };
    }

    render() {
        return (
            <Image
              { ...this.containerProps() }
            />
        );
    }
}

export default ImageContainer;