// @ts-nocheck
/* eslint-disable */

import { CategoryQuery } from 'SourceQuery/Category.query';
import { Field, Query } from '@tilework/opus';

/** @namespace Query/SubCategory/Query */
export class SubCategoryQuery extends CategoryQuery {
    
    getCategories(filters) {

        const query = new Query('categories')

        query.addArgument('filters', 'CategoryFilterInput', filters)
        query.addField(this._getItems())
        return query
    }

    _getItems() {
        return new Field('items', true)
            .addFieldList(this._getDefaultFields())
            .addField(this._getChildrenFields())
    }
}

export default new SubCategoryQuery();
