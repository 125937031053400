/* eslint-disable */
// @ts-nocheck

import React from 'react';
import { PureComponent } from 'react';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import Html from 'Component/Html';
import Link from 'Component/Link';
import ProductCard from 'Component/ProductCard';
import './SubCategory.style';

/** @namespace Component/SubCategory/Component */
export class SubCategoryComponent extends PureComponent {

    renderChildrens(categories) {

        if(!categories){
            return null;
        }

        if(categories.length === 0) {
            return (
                <div block="SubCategoryPage" elem="CategoryMissing">
                    <h2>{ __('We are sorry!') }</h2>
                    <h3>{ __('No sub category found') }</h3>
                </div>
            )
        }

        return (
            <div block="SubCategoryPage" elem="Items">
                {categories.map((item, index) => (
                    <div block="SubCategoryPage" elem="Item">
                        <Link
                            key={index}
                            block="SubCategoryPage" elem="ItemLink"
                            to={item.url}
                        >
                            {this.renderImage(item)}
                            <div block="SubCategoryPage" elem="Name">
                                {item.name}
                            </div>
                        </Link>
                    </div>
                ))}   
            </div>
        );
    }

    renderImage(item) {
        const { image, name } = item;

        // Remove the "pub/" from the image path
        const imageUrl = image.replace("pub/", '')

        return (
            <div block="SubCategoryPage" elem="Image">
                <Image
                    src={ imageUrl }
                    mix={ {
                        block: 'SubCategoryPage',
                        elem: 'Picture',
                    } }
                    ratio={ ImageRatio.IMG_SQUARE }
                    alt={ `Category - ${name}` }
                    isShowPlaceholder={true}
                /> 
            </div>
        );
    }

    renderDescription() {
        const { category: { description } } = this.props;

        if (!description) {
            return null;
        }

        return (
            <div block="SubCategoryPage" elem="Description">
                <Html content={ description } />
            </div>
        );
    }

    renderCategory() {
        const { category } = this.props;

        return (
            <div block="SubCategoryPage" elem="TopSection">
                {/* {this.renderImage(category)} */}
                {this.renderDescription()}
            </div>
        );
    }

    renderPlaceholder() {
        return (
            <div block="SubCategoryPage" elem="Loader">
                {/* <Loader /> */}
            </div>
        )
    }

    renderSimilarProductsCard(products) {
        return (
            <>
                {products.map((product, i) => (
                    <ProductCard
                        key={ product.id || i }
                        product={ product }
                    />
                ))}
            </>
        );
    }

    renderSimilarProducts() {
        const { 
            products,
            category: {
                similar_product_title,
            }
        } = this.props;
        
        if(similar_product_title){
            return (
                <div block="SubCategoryPage" elem="SimilarProducts">
                    <h2 block="SubCategoryPage" elem="SimilarProductsTitle">{similar_product_title}</h2>
                    {this.renderSimilarProductsCard(products)}
                </div>
            )
        }

        return null
    }

    renderSimilarBrands() {
        const { 
            brands,
            category: {
                similar_brand_title,
            }
        } = this.props;


        if(similar_brand_title){
            return (
                <div block="SubCategoryPage" elem="SimilarBrands">
                    <h2 block="SubCategoryPage" elem="SimilarBrandsTitle">{similar_brand_title}</h2>
                    {this.renderChildrens(brands)}
                </div>
            )
        }

        return null
    }

    render() {

        const { isCurrentCategoryLoaded, childrens } = this.props;
        if(!isCurrentCategoryLoaded){
            return this.renderPlaceholder();
        }

        return (
            <div block="SubCategoryPage">
                {this.renderCategory()}
                {this.renderChildrens(childrens)}
                {this.renderSimilarBrands()}
                {/* {this.renderSimilarProducts()} */}
            </div>
        );
    }
}

export default SubCategoryComponent;
