Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/iocheckout/src/plugin/Header.plugin.tsx')]);
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */ 

import { PureComponent } from 'react';

import SearchField from 'Component/SearchField';

/** @namespace Component/Header/Component */
export class MobileSearchField extends PureComponent {
    render() {

        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            isCheckout,
            hideActiveOverlay,
            isMobile
        } = this.props;

        if (isCheckout || !isMobile) {
            return null;
        }

        return (
            <div block="ContentWrapper">
                <SearchField
                    key="mobile_search"
                    searchCriteria={ searchCriteria }
                    onSearchOutsideClick={ onSearchOutsideClick }
                    onSearchBarFocus={ onSearchBarFocus }
                    onSearchBarChange={ onSearchBarChange }
                    onClearSearchButtonClick={ onClearSearchButtonClick }
                    isVisible={ true }
                    isActive={ searchCriteria === '' ? false : true }
                    hideActiveOverlay={ hideActiveOverlay }
                />
            </div>
        );
    }
}

export default MobileSearchField;