/* eslint-disable */
// @ts-nocheck

import { ImageComponent as SourceImageComponent } from 'SourceComponent/Image/Image.component';
import productPlaceholder from 'Style/icons/products-placeholder.png';
import './Image.override.style';

/** @namespace Component/Image/Component */
export class ImageComponent extends SourceImageComponent
{
    renderImageNotFound(){

        if (navigator.onLine) {

            const {
                isShowPlaceholder,
                isShowTextImageNotFound
            } = this.props;

            if(isShowPlaceholder){

                const {
                    alt,
                    title,
                    height,
                    width
                } = this.props;
                const {
                    isLazyLoading,
                } = this.state;

                return (
                    <img
                      block="Image" elem="showPlaceholder"
                      src={ productPlaceholder }
                      alt={ alt }
                      title={ title }
                      height={ height || '200px' }
                      width={ width || '200px' }
                      loading={ isLazyLoading ? 'lazy' : 'eager' }
                    />
                );
            }

            if(!isShowTextImageNotFound){
                return null
            }

            return (
                <span block="Image" elem="Content">{ __('Image not found') }</span>
            );
        }

        return <span block="Image" elem="Content" mods={ { isOffline: true } } />;
    }
}

export default ImageComponent;