/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import ProductCard from './ProductCard.component';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer,
} from 'Component/Product/Product.container';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { getSmallImage, getProductInStock } from 'Util/Product/Extract';
import history from 'Util/History';
import { appendWithStoreCode, objectToUri } from 'Util/Url';
import fromCache from 'Util/Cache/Cache';



/** @namespace Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    baseLinkUrl: state.ConfigReducer.base_link_url || '',
    productUsesCategories: state.ConfigReducer.product_use_categories || false,
    categoryUrlSuffix: state.ConfigReducer.category_url_suffix,
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace Component/ProductCard/Container */
export class ProductCardContainer extends ProductContainer {
    static defaultProps = {
        ...ProductContainer.defaultProps,
        hideWishlistButton: false,
        hideCompareButton: false,
        renderContent: null,
        isLoading: false,
        children: null,
        mix: {},
        layout: CategoryPageLayout.GRID,
        hideAddToCardButton: false,
    };

    containerFunctions = {
        ...this.containerFunctions,
        showSelectOptionsNotification: this.showSelectOptionsNotification.bind(this),
    };

    containerProps() {
        const {
            children,
            mix,
            layout,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
           
            renderContent,
            product,
            isPlp,
            onLoad,
            isMobile,
            hideAddToCardButton
        } = this.props;

        return {
            ...super.containerProps(),
            children,
           
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            layout,
            mix,
            renderContent,
            isPlp,
            thumbnail: getSmallImage(this.getActiveProduct()) || getSmallImage(product),
            linkTo: this.getLinkTo(),
            onLoad,
            isMobile,
            hideAddToCardButton,
            inStock: fromCache(getProductInStock, [product])
        };
    }

    getLinkTo() {
        const {
            baseLinkUrl,
            productUsesCategories,
            categoryUrlSuffix,
            product: { url, url_rewrites = [] },
            product,
        } = this.props;
        const { pathname: storePrefix } = new URL(baseLinkUrl || window.location.origin);
        const { location: { pathname } } = history;
        if (!url) {
            return undefined;
        }
        
        const { parameters } = this.state;
        const { state: { category = null } = {} } = history.location;
        const categoryUrlPart = pathname.replace(storePrefix, '').replace(categoryUrlSuffix, '');
        const productUrl = `${categoryUrlPart}/${url.replace(storePrefix, '')}`;
        
        // if 'Product Use Categories' is enabled then use the current window location to see if the product
        // has any url_rewrite for that path. (if not then just use the default url)
        const rewriteUrl = url_rewrites.find(({ url }) => url.includes(productUrl)) || {};
        const rewriteUrlPath = productUsesCategories
            ? (rewriteUrl.url && appendWithStoreCode(rewriteUrl.url)) || url
            : url;
        
        return {
            pathname: rewriteUrlPath,
            state: { product, prevCategoryId: category },
            search: objectToUri(parameters),
        };
    }


        
        showSelectOptionsNotification() {
            const { showNotification } = this.props;
        
            showNotification(NotificationType.INFO, __('Please, select product options!'));
        }
        
        render() {
            return (
                <ProductCard
                  {...this.containerFunctions}
                  {...this.containerProps()}
                />
            );
        }
    }     
        export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
        
        

