/* eslint-disable */
// @ts-nocheck

import {
    SliderQuery as SourceSliderQuery
} from "SourceQuery/Slider.query";

import { Field } from '@tilework/opus';

/**
 * Slider Query
 * @class Slider
 * @namespace Query/Slider/Query */
export class SliderQuery extends SourceSliderQuery {
    _getSlideFields() {
        return [
            new Field('slide_text'),
            new Field('slide_id'),
            new Field('mobile_image'),
            new Field('desktop_image'),
            new Field('title'),
            new Field('is_active'),
            new Field('slide_link'),
        ];
    }
}

export default new SliderQuery();