/* eslint-disable */

import {
    CmsBlockComponent as SourceCmsBlockComponent,
} from 'SourceComponent/CmsBlock/CmsBlock.component';
import Html from 'Component/Html';

import { LIST_OF_REMOVE_CMS_BLOCK_WRAPPER_DIV_IDENTIFIER } from 'Util/CmsBlock';

import './CmsBlock.override.style';

/** @namespace Component/CmsBlock/Component */
export class CmsBlockComponent extends SourceCmsBlockComponent {  
    renderTextPlaceholder() {
        const {
            placeHolderSize,
            placeHolderMobileSize,
            device,
        } = this.props;

        if (device.isMobile && placeHolderMobileSize.width && placeHolderMobileSize.height) {
            return <div block="ContentWrapper">
                <div style={placeHolderMobileSize}  block="CmsBlock" elem="Loader" mix={{block:'MobileLoader'}} />
            </div>
        }

        return <div block="ContentWrapper">
            <div style={placeHolderSize}  block="CmsBlock" elem="Loader" />
        </div>
    }

    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled,
            },
            blockType,
        } = this.props;
        
        
        
        if (!content) {
            return this.renderTextPlaceholder();
        }

        if (!content || disabled) {
            return null
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        if(LIST_OF_REMOVE_CMS_BLOCK_WRAPPER_DIV_IDENTIFIER.includes(identifier)) {
            return <Html content={ content } />;
        }

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
              mods={ { type: blockType } }
            >
                <Html content={ content } />
            </div>
        );
    }
}

export default CmsBlockComponent;