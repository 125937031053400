Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/infinite_scroll/src/plugin/ProductListContainer.plugin.js')]);
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
// @ts-nocheck

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { 
    ProductListContainer as SourceProductListContainer,
    mapStateToProps, 
    mapDispatchToProps 
} from 'SourceComponent/ProductList/ProductList.container';

/** @namespace Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {

    componentDidUpdate(prevProps) {

        const {
            sort,
            search,
            filter,
            pages,
            device,
            isPlp,
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();
        const isProductListUpdated = JSON.stringify(filter) !== JSON.stringify(prevFilter)
                                    || currentPage !== prevPage;

        const isSortUpdated = JSON.stringify(sort) !== JSON.stringify(prevSort);

        if (isProductListUpdated || isSortUpdated) {
            window.isPrefetchValueUsed = false;
        }

        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        // if (device.isMobile && this._getIsInfiniteLoaderEnabled() && isPlp && !(isProductListUpdated || isSortUpdated)) {
        //     return;
        // }

        if (!device.isMobile && (search !== prevSearch || isProductListUpdated)) {
            this.requestPage(this._getPageFromUrl());
        }

        if(isProductListUpdated){
            this.requestPage(this._getPageFromUrl());
        }

        if (isSortUpdated) {
            const isOnlySortUpdated = true;
            this.requestPage(this._getPageFromUrl(), false, isOnlySortUpdated);
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
