// @ts-nocheck
/* eslint-disable */

import { 
    UPDATE_BRANDS,
    UPDATE_PRODUCTS,
    IS_LOADING
} from "./SubCategory.type";


export const getInitialState = () => ({
    isLoading: false,
    brands: [],
    products: []
});

export const SubCategoryReducer = (
    state= getInitialState(),
    action,
) => {
    const {
        type
    } = action;

    switch (type) {
        case UPDATE_PRODUCTS:

            const {products} = action;

            return {
                ...state,
                products
            };

        case UPDATE_BRANDS:

            const {brands} = action;

            return {
                ...state,
                brands
            };

        case IS_LOADING:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };

        default:
            return state;
    }
};

export default SubCategoryReducer;


