Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/faqs/src/plugin/CategoryQuery.plugin.js')]);
/* eslint-disable */
// @ts-nocheck

import { Field } from '@tilework/opus';
import { CategoryQuery as SourceCategoryQuery } from 'SourceQuery/Category.query';


/** @namespace Query/Category/Query */
export class CategoryQuery extends SourceCategoryQuery
{
    _getDefaultFields() {

        const superFields = super._getDefaultFields();

        return superFields.concat(
            [
                new Field('similar_brand_title'),
                new Field('similar_brand'),
                new Field('similar_product_title'),
                new Field('similar_product'),
            ]
        );
    }
}

export default new CategoryQuery();