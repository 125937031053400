/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */ 

import React from "react"
import { connect } from 'react-redux';
import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceRoute/HomePage/HomePage.container';
import CmsBlock from 'Component/CmsBlock';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import Footer from 'Component/Footer';
import MobileSearchField from 'Component/MobileSearchField';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import './HomePage.override.style';
import CmsPage from 'Route/CmsPage';
import CmsBlockQuery from 'Query/CmsBlock.query';
import DataContainer from 'Util/Request/DataContainer'

/** @namespace Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    toggleBreadcrumbs: (isVisible) => dispatch(toggleBreadcrumbs(isVisible))
});

/** @namespace Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    
    state = {
        isInstallPromptAvailable: window.isInstallPromptAvailable,
        isDescriptionExpanded: false,
        cmsBlocks: []
    };

    toggleDescription = () => {
        this.setState({ isDescriptionExpanded: !this.state.isDescriptionExpanded });
    }

    componentDidMount() {
        super.componentDidMount();
        
        const { toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(false);

        this.getAllCmsBlocks();
        this.isShowOrHideReadmoreBtn();
    }

    componentDidUpdate(preProps) {
        this.isShowOrHideReadmoreBtn();
    }

    getAllCmsBlocks() {
        const identifiers = [
            'top-offer-banner',
            'home-herobanner',
            'category-gallery',
            'featured-products',
            'best-brands',
            'full-offer-banner',
            'trending-categories',
            'new-arrivals',
            'home-gallery',
            'home-bottom-description'
        ]

        // DataContainer().fetchData(
        //     [CmsBlockQuery.getQuery({ identifiers: [identifiers] })],
        //     ({ cmsBlocks: { items } }) => {
        //         if (!items.length) {
        //             return;
        //         }
        //         this.setState({ cmsBlocks: items });
        //     },
        // );

    }

    isShowOrHideReadmoreBtn() {

        const checkingClass = setInterval(() => {
            const descBodyContent = document.querySelector(".hm-descr-body .CmsBlock-Wrapper .page-section-head")
            if(descBodyContent){
                clearInterval(checkingClass);
                document.querySelector(".hm-descr-readmore").classList.remove("hide");
            }
        }, 1000)
    }
    
    render() {
        const { isInstallPromptAvailable, isDescriptionExpanded } = this.state;

        const descriptionExpandedActive = isDescriptionExpanded ? 'active' : '';

        return (
            <div block="HomePage" mods={ { mobile: !!isInstallPromptAvailable } }>
                <main block="CmsPage">
                    <div block="CmsPage" elem="Content">                        
                        <MobileSearchField />
                        <div block="topOfferBanner" elem="Wrapper">
                            <CmsBlock identifier={`top-offer-banner`} placeHolderSize={{width:'100%', height:'333px'}}  placeHolderMobileSize={{width:'100%', height:'140px'}} />
                        </div>
                    
                        <CmsBlock identifier='home-herobanner' placeHolderSize={{width:'100%', height:'590px'}}  placeHolderMobileSize={{width:'100%', height:'456px'}} />
                    
                        <CmsBlock identifier='category-gallery' placeHolderSize={{width:'100%', height:'941px'}}  placeHolderMobileSize={{width:'100%', height:'636px'}} />
                    
                        <CmsBlock identifier='featured-products' placeHolderSize={{width:'100%', height:'739px'}}  placeHolderMobileSize={{width:'100%', height:'596px'}} />
                    
                        <div block="hm-brands" elem="sect" mix={{ block:"page-section"}}>
                            <div block="ContentWrapper">
                                <div block="page-section" elem="head">
                                    <h2 block="page-section" elem="title">{ __('SHOP THE BEST BRANDS') }</h2>
                                </div>
                                <div block="page-section" elem="body">
                                    <CmsBlock identifier='best-brands' placeHolderSize={{width:'100%', height:'147px'}}  placeHolderMobileSize={{width:'100%', height:'83px'}} />
                                </div>
                            </div>
                        </div>
                        <CmsBlock identifier='full-offer-banner' placeHolderSize={{width:'100%', height:'1338px'}}  placeHolderMobileSize={{width:'100%', height:'395px'}} />
                        <div block="hm-trend-cat" elem="sect" mix={{ block:"page-section"}}>
                            <div block="ContentWrapper">
                                <div block="page-section" elem="head">
                                    <h2 block="page-section" elem="title">{ __('Trending Categories') }</h2>
                                </div>
                                <div block="page-section" elem="body">
                                    <CmsBlock identifier='trending-categories' placeHolderSize={{width:'100%', height:'135px'}}  placeHolderMobileSize={{width:'100%', height:'133px'}} />
                                </div>
                            </div>
                        </div>
                        <RenderWhenVisible>
                            <CmsBlock identifier='new-arrivals' />
                        </RenderWhenVisible>
                        <RenderWhenVisible>
                            <CmsBlock identifier='home-gallery' />
                            <div block="hm-descr" elem="sect" mix={{ block:"page-section"}}>
                                <div block="ContentWrapper">
                                    <div block="hm-descr" elem="body" mix={{block:descriptionExpandedActive}}>
                                        <CmsBlock identifier='home-bottom-description' />
                                        <div block="hm-descr" elem="readmore hide" >
                                            <div block="hm-descr" elem="readmorebtn" onClick={this.toggleDescription} >
                                                {descriptionExpandedActive ? __('Read Less') : __('Read More') } <ChevronIcon direction={ Directions.RIGHT } />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RenderWhenVisible>
                    </div>
                </main>
                <div block="CmsPage" elem="HomeWrapper">
                    <CmsPage { ...this.containerProps() } />
                </div>
                <Footer isVisibleOnMobile />
            </div>
        );
    }
   
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
