/* eslint-disable */
// @ts-nocheck

import { Link as RouterLink } from 'react-router-dom';
import Loader from 'Component/Loader';
import { stringify } from 'rebem-classname';
import {
    LinkComponent as SourceLinkComponent
} from 'SourceComponent/Link/Link.component';
import history from 'Util/History';

const GO_TO_CONTENT = [
    '/scheduled-orders',
]

export class LinkComponent extends SourceLinkComponent {

    render() {
        const {
            className,
            bemProps,
            children,
            to,
            isOpenInNewTab,
            showLoader,
            ...props
        } = this.props;

        const { location: { pathname } } = history;

        if (!to) {
            return (
                <div { ...props } { ...bemProps }>
                    { children }
                </div>
            );
        }

        if (/^#/.test(to)) {
            return this.renderRelativePathLink();
        }

        if (/#/.test(to) && GO_TO_CONTENT.includes(pathname)) {
            return this.renderRelativePathLink();
        }

        const classNameConverted = `${ className } ${ stringify(bemProps)}`;

        if (/^https?:\/\//.test(to) || isOpenInNewTab) {
            return this.renderAbsolutePathLink(classNameConverted);
        }

        if (showLoader) {
            const {
                isLoaderActive,
                handleLinkClick,
                ...otherProps
            } = this.props;

            return (
                <>
                    <div
                      block="Link"
                      elem="LoaderWrapper"
                      mods={ { isLoaderActive } }
                    >
                        <Loader />
                    </div>
                    <div
                      block="Link"
                      elem="Button"
                      role="button"
                      tabIndex={ 0 }
                      onKeyDown={ handleLinkClick }
                      { ...otherProps }
                    // eslint-disable-next-line react/forbid-dom-props
                      className={ classNameConverted }
                      onClick={ handleLinkClick }
                    >
                        { children }
                    </div>
                </>
            );
        }

        return (
            <RouterLink
              { ...props }
              to={ to }
              // eslint-disable-next-line react/forbid-component-props
              className={ classNameConverted }
            >
                { children }
            </RouterLink>
        );
    }

}

export default LinkComponent;