/* eslint-disable */
/* stylelint-disable */
// @ts-nocheck

import { connect } from 'react-redux';
import { PureComponent } from 'react';
import ProductFbtDispatcher from 'Store/ProductFbt/ProductFbt.dispatcher';
import ProductFbt from './ProductFbt.component';

/** @namespace Component/Productfbt/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isAdding: state.ProductFbtReducer.isAdding,
});

/** @namespace Component/Productfbt/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addToCart: (products) => ProductFbtDispatcher.addToCart(dispatch, products),
});

/** @namespace Component/ProductFbt/Container */
export class ProductFbtContainer extends PureComponent {

    state = {
        checkedProducts: []
    }

    __construct(props){
        super.__construct?.(props);
    }

    manageCheckedProducts() {
        const { data: product } = this.props;

        if (product?.mp_fbt_products && product?.mp_fbt_products.length > 0) {

            let checkedProducts = []

            product.mp_fbt_products.forEach((_fbtProducts)=>{
                checkedProducts.push({
                    isSelected: true,
                    product: _fbtProducts
                })
            })

            this.setState({
                checkedProducts
            })
        }
    }

    containerFunctions = {
        addToCart: this.addToCart.bind(this),
        checkedOrUnCheckedProduct: this.checkedOrUnCheckedProduct.bind(this),
        addToCartAvailable: this.addToCartAvailable.bind(this)
    };

    addToCartAvailable() {
        let isDisable = true;
        const { checkedProducts } = this.state;

        if(checkedProducts.length > 0){

            checkedProducts.forEach((checkedProduct) => {
                if(checkedProduct.isSelected){
                    isDisable = false
                }
            })
        }

        return isDisable
    }

    addToCart() {
        const { addToCart } = this.props
        const { checkedProducts } = this.state
        addToCart(checkedProducts)
    }

    checkedOrUnCheckedProduct(product, isChecked) {

        const { checkedProducts } = this.state

        this.setState({
            checkedProducts: checkedProducts.map((checkedProduct) => {
                return {
                    ...checkedProduct,
                    isSelected: checkedProduct.product.id === product.id ? isChecked : checkedProduct.isSelected
                }
            })
        })
    }

    containerProps() {
        const {
            data: product,
            isAdding
        } = this.props;

        const {
            checkedProducts
        } = this.state

        if(checkedProducts.length === 0){
            this.manageCheckedProducts()
        }

        return {
            checkedProducts,
            product,
            isAdding
        };
    }

    render() {

        return <ProductFbt
            { ...this.containerFunctions }
            { ...this.containerProps() }
        />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFbtContainer);
