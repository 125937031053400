/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
/* eslint-disable spaced-comment */

import { ProductCardComponent as SourceProductCardComponent } from "SourceComponent/ProductCard/ProductCard.component";
import AsapDelivery from "Component/AsapDelivery";
import Html from "Component/Html";
import Loader from "Component/Loader";
import { CategoryPageLayout } from "Route/CategoryPage/CategoryPage.config";
import { ProductType } from "Component/Product/Product.config";
import "./ProductCard.override.style";
/**
 * Product card
 * @class ProductCard
 /* @namespace Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
  requiresConfiguration() {
    const {
      parameters,
      product: {
        type_id: type,
        options = [],
        items = [],
        links_purchased_separately,
      },
      layout
    } = this.props;

    const configureBundle = type === ProductType.BUNDLE;

    const allAttrs = super.getConfigurableAttributes();
    const plpConfigurableAttrs = this.getConfigurableAttributes();

    const isConfigurable = type === ProductType.CONFIGURABLE;

    const configureConfig =
      isConfigurable &&
      (Object.keys(allAttrs).length !==
        Object.keys(plpConfigurableAttrs).length ||
        Object.values(plpConfigurableAttrs).some(
          (value) => value.attribute_values.length === 0
        ) ||
        (Object.keys(allAttrs).length > 0 &&
          Object.keys(parameters).length === 0));

    const configureGrouped =
      type === ProductType.GROUPED && items.every(({ qty }) => qty === 0);

    let configureCustomize = false
    if(options){
      configureCustomize = options.some(({ required = false }) => required);
    }

    const configureDownloadableLinks =
      ProductType.DOWNLOADABLE && links_purchased_separately === 1;

    return (
      configureGrouped ||
      configureBundle ||
      configureConfig ||
      configureCustomize ||
      configureDownloadableLinks
    );
  }

  renderAddToCart() {
    const {
      layout,
      showSelectOptionsNotification,
      inStock,
      hideAddToCardButton,
      product
    } = this.props;

    if (hideAddToCardButton) {
      return null;
    }

    const requiresConfiguration = this.requiresConfiguration();

    if (inStock && requiresConfiguration) {
      return (
        <button
          block="Button AddToCart"
          mods={{ layout }}
          onClick={showSelectOptionsNotification}
        >
          {__("Add to cart")}
        </button>
      );
    }

    if (!inStock) {
      return (
        <div block="ProductCard" elem="OutOfStock">
          <p>{__("Out of stock")}</p>
        </div>
      );
    }

    return this.renderAddToCartButton(layout);
  }

  renderfbtimage() {
    const { product } = this.props;
    return <img src={product.image?.url} alt={product.name} />;
  }
  renderVisibleOnHover() {
    const { device } = this.props;

    // if (device.isMobile) {
    //     return null;
    // }

    return (
      <>
        {this.renderConfigurableOptions()}
        <div block="ProductCard" elem="Footer">
          {this.renderAddToCart()}
        </div>
      </>
    );
  }

  renderShortDescription() {
    const {
      product: { short_description },
    } = this.props;
    const { html } = short_description || {};

    if (!html) {
      return null;
    }

    return (
      <div block="ProductCard" elem="ShortDescription">
        <Html content={html} />
      </div>
    );
  }
  renderPrice() {
    const {
      getActiveProduct,
      product: { type_id: baseType } = {},
      inStock,
    } = this.props;

    const { price_range: priceRange, type_id: typeId } = getActiveProduct();

    if (!priceRange) {
      return this.renderTextPlaceholder();
    }

    if (baseType === ProductType.CONFIGURABLE && !inStock) {
      return null;
    }

    // If product is not a variant.
    const notConfigured =
      baseType !== ProductType.CONFIGURABLE || typeId === baseType;

    return super.renderPrice(notConfigured);
  }

  renderCardContent() {
    const { renderContent, product, isMobile } = this.props;

    const image = product.short_description;
    if (renderContent) {
      return renderContent(this.contentObject);
    }

    return this.renderCardLinkWrapper(
      <div
        block="ProductCard"
        elem="LinkInnerWrapper"
        mods={{ loaded: !!name }}
      >
        <div block="ProductCard" elem="FigureReview">
          <figure block="ProductCard" elem="Figure">
            {isMobile && (
              <div block="ProductCard" elem="MobileActions">
                {this.renderProductCardWishlistButton()}
                {this.renderProductCompareButton()}
              </div>
            )}
            {!image ? (
              <div block="ProductCard" elem="image">
                {this.renderfbtimage()}
              </div>
            ) : (
              this.renderPicture()
            )}
          </figure>
          {this.renderProductActions()}
        </div>
        <div block="ProductCard" elem="Content">
          {this.renderName(false)}
          {this.renderReviews()}
          {this.renderBrand()}

          {this.renderPrice()}
          {this.renderShortDescription()}
          <AsapDelivery product={product} />
        </div>
        {this.renderVisibleOnHover()}
      </div>
    );
  }

  renderCardListContent() {
    const {
      children,
      layout,
      renderContent,
      product,
      product: { name },
    } = this.props;
    const image = product.short_description;

    if (renderContent) {
      return renderContent(this.contentObject);
    }

    return this.renderCardLinkWrapper(
      <div block="ProductCard" elem="Link">
        <div block="ProductCard" elem="FigureReview">
          <figure block="ProductCard" elem="Figure">
            {!image ? this.renderfbtimage() : this.renderPicture()}
          </figure>
        </div>
        <div block="ProductCard" elem="Content" mods={{ layout }}>
          <div block="ProductCard" elem="MainInfo">
            {this.renderMainDetails()}
            {this.renderReviews()}
            {this.renderBrand()}
          </div>
          <div block="ProductCard" elem="AttributeWrapper">
            {this.renderPrice()}
            {this.renderShortDescription()}
            <AsapDelivery product={product} />
            {this.renderConfigurableOptions()}
          </div>
          <div
            block="ProductCard"
            elem="ActionWrapper"
            mods={{ loaded: !!name }}
          >
            {this.renderAddToCart()}
            {this.renderProductActions()}
          </div>
          <div block="ProductCard" elem="AdditionalContent">
            {children}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { children, mix, isLoading, layout } = this.props;

    if (layout === CategoryPageLayout.LIST) {
      return (
        <div block="ProductCard" mods={{ layout }} mix={mix}>
          <Loader isLoading={isLoading} />
          {this.renderCardListContent()}
        </div>
      );
    }

    return (
      <div block="ProductCard" mods={{ layout }} mix={mix}>
        <Loader isLoading={isLoading} />
        {this.renderCardContent()}
        <div block="ProductCard" elem="AdditionalContent">
          {children}
        </div>
      </div>
    );
  }
}

export default ProductCardComponent;
