/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
// @ts-nocheck

import { Suspense } from 'react';
import {  withRouter } from 'react-router';
import {
    ProductGalleryComponent as SourceProductGalleryComponent,
} from "SourceComponent/ProductGallery/ProductGallery.component";
import Share from 'Component/Share';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import Slider from 'SourceComponent/Slider';

import './ProductGallery.override.style';

export const VideoPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-overlays" */
    'Component/VideoPopup'
));

/**
 * Product gallery
 * @class ProductGallery
 * @namespace Component/ProductGallery/Component
 */
export class ProductGalleryComponent extends SourceProductGalleryComponent {

    renderSlider() {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            isMobile,
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive,
        };

        const isMoreThanOnePhoto = gallery.length > 1;

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
              data-is-pdp
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCounter={ isMobile }
                  showArrows={ !isMobile && isMoreThanOnePhoto }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  gallery={ gallery }
                  isHeightTransitionDisabledOnMount
                  
                >
                    { this.renderPlaceholderImage() }
                    { gallery.map(this.renderSlide) }
                </Slider>
            </div>
        );
    }

    render() {

        const { productName } = this.props;

        return (
            <div block="ProductGallery" ref={ this.galleryRef }>
                <Share
                    title={ productName }
                />
                { this.renderSlider() }
                { this.renderAdditionalPictures() }
                <Suspense fallback={ null }>
                    <VideoPopup />
                </Suspense>
            </div>
        );
    }
   
}

export default withRouter(ProductGalleryComponent);
