// @ts-nocheck
/* eslint-disable */


import { 
    UPDATE_BRANDS,
    UPDATE_PRODUCTS,
    IS_LOADING,
} from "./SubCategory.type";

export const updateBrandsAction = ( brands ) => ({
    type: UPDATE_BRANDS,
    brands
});

export const isLoadingAction = (isLoading) => ({
    type: IS_LOADING,
    isLoading
});

export const updateProductsAction = ( products ) => ({
    type: UPDATE_PRODUCTS,
    products
});
