Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/faqs/src/plugin/ProductPageComponent.plugin.js')]);
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
import { Suspense } from 'react';
import {
    ProductPageComponent as SourceProductPageComponent,
} from "SourceRoute/ProductPage/ProductPage.component";
import TextPlaceholder from 'Component/TextPlaceholder';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import { ProductPageTabs } from 'Route/ProductPage/ProductPage.config';
import ContentWrapper from 'Component/ContentWrapper';
import { LinkedProductType } from 'Store/LinkedProducts/LinkedProducts.type';
import NoMatchHandler from 'Route/NoMatchHandler';
import Footer from 'Component/Footer';
import ProductFbt from 'Component/ProductFbt';
import CmsBlock from 'Component/CmsBlock';

export const ProductActions = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays" */
    'Component/ProductActions'
));

import './ProductPage.override.style';
export const ProductLinks = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductLinks'
));

/** @namespace Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    tabMap = {
        [ProductPageTabs.INFORMATION]: {
            name: __('Description'),
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: () => this.renderProductInformationTab(),
        },
        [ProductPageTabs.ATTRIBUTES]: {
            name: __('Specifications'),
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: () => this.renderProductAttributesTab(),
        },
        // [ProductPageTabs.REVIEWS]: {
        //     name: __('Reviews'),
        //     shouldTabRender: () => {
        //         const { areReviewsEnabled } = this.props;

        //         return areReviewsEnabled;
        //     },
        //     render: () => this.renderProductReviewsTab(),
        // },
    };
    renderPrice() {
        const {
            getActiveProduct,
            product: {
                type_id: baseType,
            } = {},
            inStock,
        } = this.props;

        const {
            price_range: priceRange,
            type_id: typeId,
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        if (baseType === ProductType.CONFIGURABLE && !inStock) {
            return null;
        }

        // If product is not a variant.
        const notConfigured = baseType !== ProductType.CONFIGURABLE || typeId === baseType;

        return super.renderPrice(notConfigured);
    }


    renderAdditionalSections() {
        const {
            areDetailsLoaded,
            dataSource
        } = this.props;
        
        return (
            <>
                {this.renderProductTabs()}
                <ProductFbt data={dataSource} />
                <Suspense fallback={null}>
                    <ProductLinks
                   
                    linkType={LinkedProductType.RELATED}
                    title={__('Recommended for you')}
                    areDetailsLoaded={areDetailsLoaded}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <ProductLinks
                    product={dataSource}
                        linkType={LinkedProductType.UPSELL}
                        title={__('You might also like')}
                        areDetailsLoaded={areDetailsLoaded}
                    />
                </Suspense>
                <Suspense>
                    <CmsBlock identifier={ 'recently-viewed-products' } />
                </Suspense>
                {this.renderProductReviewsTab()}
            </>
        );
    }


    renderProductBrand() {
        const {
            dataSource: {
                attributes: { manufacturer: { attribute_value: manufacturer = '', attribute_options: manufacturer_options = '' } = {} } = {},
            },
        } = this.props;

        if (!manufacturer) {
            return null;
        }

        return (
            <>
                <meta itemProp="brand" content={manufacturer_options[manufacturer].label ?? ''} />
                <h2 block="ProductPage" elem="Brand" itemProp="brand">
                    <TextPlaceholder content={manufacturer_options[manufacturer].label ?? ''} />
                </h2>
            </>
        );
    }

    renderProductDesktopMainData() {
        return (
            <>
                {this.renderProductName()}
                {this.renderProductBrand()}

            </>
        );
    }


    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
            isMobile,
        } = this.props;

        return (
            <div block="ProductPage" elem="ProductActions">

                {this.renderProductDesktopMainData()}
                <Suspense fallback={this.renderProductActionsPlaceholder()}>
                    <ProductActions
                        getLink={getLink}
                        product={dataSource}
                        parameters={parameters}
                        areDetailsLoaded={areDetailsLoaded}
                        setActiveProduct={setActiveProduct}
                    />
                </Suspense>
            </div>
        );
    }
    render() {
        return (
            <NoMatchHandler>
                <main
                    block="ProductPage"
                    aria-label="Product page"
                    itemScope
                    itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                        wrapperMix={{ block: 'ProductPage', elem: 'Wrapper' }}
                        label={__('Main product details')}
                    >
                        {this.renderProductPageContent()}
                    </ContentWrapper>
                    {this.renderAdditionalSections()}
                    {this.renderReviewPopup()}
                    <Footer isVisibleOnMobile />
                </main>
            </NoMatchHandler>
        );
    }
}
export default ProductPageComponent;