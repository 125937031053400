/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
/* eslint-disable spaced-comment */
// @ts-nocheck

import { SliderWidgetComponent as SourceSliderWidgetComponent } from "SourceComponent/SliderWidget/SliderWidget.component";
import Html from 'Component/Html';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import Link from 'Component/Link';
/**
 * Homepage slider
 * @class SliderWidget
 * @namespace Component/SliderWidget/Component
 */
export class SliderWidgetComponent extends SourceSliderWidgetComponent {
    renderSlideImage(slide, i) {
        const { onLoad } = this.props;
        const {
            slide_text,
            isPlaceholder,
            slide_link,
            title: block,
        } = slide;
        
        if (slide_link) {
            return (
                <figure
                    block="SliderWidget"
                    elem="Figure"
                    key={ i }
                >
                    <Link
                        block="SliderWidget"
                        elem="Link"
                        to={ slide_link }
                    >
                        <Image
                            mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                            ratio={ ImageRatio.IMG_CUSTOM }
                            src={ this.getSlideImage(slide) }
                            isPlaceholder={ isPlaceholder }
                            onImageLoad={ onLoad }
                        />
                        <figcaption
                            block="SliderWidget"
                            elem="Figcaption"
                            mix={ { block } }
                        >
                            <Html content={ slide_text || '' } />
                        </figcaption>
                    </Link>
                </figure>
            );
        }
        
        return (
            <figure
              block="SliderWidget"
              elem="Figure"
              key={ i }
            >
                <Image
                  mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                  ratio={ ImageRatio.IMG_CUSTOM }
                  src={ this.getSlideImage(slide) }
                  isPlaceholder={ isPlaceholder }
                  onImageLoad={ onLoad }
                />
                <figcaption
                  block="SliderWidget"
                  elem="Figcaption"
                  mix={ { block } }
                >
                    <Html content={ slide_text || '' } />
                </figcaption>
            </figure>
        );
    }
  
}

export default SliderWidgetComponent;
